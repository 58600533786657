<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Periode Transaksi"
    allow-clear
    show-search
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :loading="state.loading"
    :show-arrow="true"
  >
    <a-select-option v-for="d in state.data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { debounce, pickBy } from 'lodash'

export default {
  props: {
    value: {
      default: () => null,
      type: String,
    },
    status: {
      default: () => '',
      type: String,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
      data: [],
      params: {
        q: '',
        status: null,
      },
    })
    const findText = ref(null)
    const valueState = ref(props.value)

    const fetchData = () => {
      state.loading = true

      apiClient
        .get('/api/filter/periode-transaksi', {
          params: pickBy(state.params),
        })
        .then(({ data }) => {
          state.data = data

          data.forEach(item => {
            if (item.status && !valueState.value) {
              valueState.value = item.id
            }
          })
        })
        .finally(() => {
          state.loading = false
        })
    }
    onMounted(() => {
      state.params.status = toRefs(props).status

      fetchData()
    })
    watch([state.params], (after, before) => {
      emit('update:value', [])
      fetchData()
    })

    watch(valueState, () => {
      emit('update:value', valueState.value)
    })

    const onSearch = debounce((e) => {
      findText.value = e
      state.params.q = e
      fetchData()
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      state,
      valueState,
      findText,
      highlight,
      onSearch,
    }
  },
}
</script>
